import { putRequest, getRequest, postBodyRequest, exportRequest } from '@/utils/axios'

// 收款列表
export function collectionPage (params) {
  return postBodyRequest('/pay/yunst/pay/collectionList', params)
}
// 付款列表
export function payList (params) {
  return postBodyRequest('/pay/yunst/pay/payList', params)
}

export function add (params) {
  return postBodyRequest('/trans/transport/line/add', params)
}

export function edit (params) {
  return postBodyRequest('/trans/transport/line/edit', params)
}
// 内部账号编辑
export function editInternalAcc (params) {
  return postBodyRequest('/pay/yunst/pay/editInternalAcc', params)
}

// 内部账号列表
export function internalAccList (params) {
  return postBodyRequest('/pay/yunst/pay/internalAccList', params)
}

export function enable (id, isnable) {
  return putRequest(`/trans/transport/line/${id}/${isnable}`)
}
// 在途总金额
export function getPayeeFundsInTransitBalance (params) {
  return getRequest('/pay/yunst/order/getPayeeFundsInTransitBalance', params)
}

export function changePayPlatform (params) {
  return getRequest('/trans/yunstpay/sandOrYunstSwitch', params)
}

export function getPayPlatform (params) {
  return getRequest('/trans/yunstpay/getSandOrYunstSwitch', params)
}

export function getVspCusConfPage (params) {
  return postBodyRequest('/pay/yunst/pay/getVspCusConfPage', params)
}
export function saveVspCusConf (params) {
  return postBodyRequest('/pay/yunst/pay/saveVspCusConf', params)
}

export function queryReserveFundBalance (params) {
  return getRequest('/pay/yunst/pay/queryReserveFundBalance', params)
}

// 收款导出
export function collectExport (params, fileName) {
  return exportRequest('/pay/yunst/pay/collectionList/export', params, fileName)
}

// 付款导出
export function payExport (params, fileName) {
  return exportRequest('/pay/yunst/pay/payList/export', params, fileName)
}

// 付款流程列表
export function getFlowInstancePayPage (params) {
  return postBodyRequest('/pay/instance/pay/getFlowInstancePayPage', params)
}

export function getFlowPaySummary (params) {
  return postBodyRequest('/pay/instance/pay/getFlowPaySummary', params)
}
// 付款总金额
export function getTotalPayAmt (params) {
  return postBodyRequest('/pay/yunst/pay/getTotalPayAmt', params)
}
// 收款总金额
export function getCollectionAmt (params) {
  return postBodyRequest('/pay/yunst/pay/collectionAmt', params)
}

export function getQueryBalance (params) {
  return getRequest('/pay/yunst/pay/queryBalance', params)
}

export function flowInstancePayExport (params, fileName) {
  return exportRequest('/pay/instance/pay/flow-instance/export', params, fileName)
}
