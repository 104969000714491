<template>
  <div style="background:#fff">
    <div style="background:#fff;padding:10px;">
      <a-form layout="inline">
        <a-form-item label="">
          <a-range-picker v-model:value="payDatetime" @change="timeChange"
            :show-time="{ format: 'HH:mm', defaultValue: defaultValue }" format="YYYY-MM-DD HH:mm:ss"
            :placeholder="['付款开始日期', '付款结束日期']" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.financeType" :options="$store.state.enumAll.financeTypeEnum"
            style="width: 183px" placeholder="账单类型">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.splitType" style="width: 183px" placeholder="分账类型">
            <a-select-option :value="1">流程分账</a-select-option>
            <a-select-option :value="2">内部分账</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.orderStatus" :options="$store.state.enumAll.paymentStatusEnum"
            style="width: 183px" placeholder="付款状态">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderNo" placeholder="账单号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.bizOrderNo" placeholder="流水号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.agentCollectOrderNo" placeholder="收款订单号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.flowInstanceId" placeholder="审批流程ID" allowClear />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearch" :loading="loading">查询</a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="onResetting" :loading="loading">重置</a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="exportData" :disabled="disabled">导出</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="padding:10px 0;padding-top:0">付款总金额(元)：<span style="color: red">{{ amtData.amt || '' }}</span></div>
    <a-table :columns="columns" :pagination="pagination" bordered size="small" :row-key="record => record.id"
      :data-source="listData" :loading="loading" @change="handleTableChange">
    </a-table>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import SuyTable from '@/components/SuyTable'
import moment from 'moment'
import { payList, payExport, getTotalPayAmt } from '@/api/yst/yst'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      loading: false,
      disabled: false,
      payDatetime: [],
      amtData: {},
      defaultValue: [moment().hours(15).minutes(0).seconds(0), moment().hours(15).minutes(0).seconds(0)],
      searchForm: {
        orderNo: '',
        bizOrderNo: '',
        agentCollectOrderNo: '',
        flowInstanceId: '',
        financeType: null,
        splitType: null,
        payMethod: null,
        orderStatus: null,
        payDatetimeStart: null,
        payDatetimeEnd: null,
        createTimeStart: null,
        createTimeEnd: null
      },
      mirrorSearchForm: {},
      listData: [],
      columns: [
        {
          title: '交易流水号',
          dataIndex: 'bizOrderNo'
        },
        {
          title: '账单号',
          dataIndex: 'orderNo'
        },
        {
          title: '收款订单号',
          dataIndex: 'agentCollectOrderNo'
        },
        {
          title: '审批流程ID',
          dataIndex: 'flowInstanceId'
        },
        {
          title: '账单类型',
          dataIndex: 'financeType.label'
        },
        {
          title: '分账类型',
          dataIndex: 'splitType.label'
        },
        {
          title: '发起人',
          width: '5%',
          dataIndex: 'sponsor'
        },
        {
          title: '发起时间',
          dataIndex: 'sponsorTime'
        },
        {
          title: '审核人',
          width: '5%',
          dataIndex: 'auditor'
        },
        {
          title: '审核时间',
          dataIndex: 'auditTime'
        },
        {
          title: '付款金额',
          width: '6%',
          dataIndex: 'amount'
        },
        {
          title: '付款时间',
          width: '6%',
          dataIndex: 'payDatetime'
        },
        {
          title: '付款状态',
          width: '6%',
          dataIndex: 'orderStatus.label'
        },
        // {
        //   title: '支付方式',
        //   dataIndex: 'payMethod.label'
        // }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const loadData = () => {
      state.loading = true
      payList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
          getTotalPayAmt({
            ...state.searchForm,
          }).then(res => {
            if (res.code === 10000) {
              state.amtData.amt = res.data
            }
          })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const onResetting = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.payDatetime = []
      onSearch()
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const timeChange = (e, v) => {
      state.searchForm.payDatetimeStart = v[0]
      state.searchForm.payDatetimeEnd = v[1]
    }
    const exportData = () => {
      state.disabled = true
      payExport({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }, '财务-付款明细').then(res => {

      }).finally(() => {
        setTimeout(() => {
          state.disabled = false
        }, 1000)
      })
    }
    return {
      ...toRefs(state),
      loadData,
      onSearch,
      timeChange,
      exportData,
      handleTableChange,
      onResetting
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
